import React from "react";

import {
	TextField,
	Card,
	CardHeader,
	CardContent,
	Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ContactForm = () => {
	const classes = useStyles();

	return (
		<Card>
			<CardHeader title="Send Us a Message" />
			<CardContent>
				<form
					name="help"
					method="POST"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
				>
					<input type="hidden" name="bot-field" />
					<input type="hidden" name="form-name" value="help" />

					<TextField
						id="help-name"
						name="name"
						label="Name"
						required
						fullWidth
						className={classes.helpField}
					/>
					<TextField
						id="help-email"
						name="email"
						type="email"
						label="Email"
						required
						fullWidth
						className={classes.helpField}
					/>
					<TextField
						id="help-org"
						name="org"
						label="Organization Name"
						required
						fullWidth
						className={classes.helpField}
					/>
					<TextField
						id="help-subject"
						name="subject"
						label="Subject"
						required
						fullWidth
						className={classes.helpField}
					/>
					<TextField
						id="help-message"
						name="message"
						label="Message"
						required
						multiline
						rows={8}
						fullWidth
						className={classes.helpField}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						className={classes.helpSubmit}
					>
						Send Request
					</Button>
				</form>
			</CardContent>
		</Card>
	);
};

const useStyles = makeStyles(theme => ({
	helpField: {
		display: "block",
		marginBottom: theme.spacing(1)
	},
	helpSubmit: {
		marginTop: theme.spacing(2)
	}
}));

export default ContactForm;
