import React from "react";
import { graphql } from "gatsby";

import { Container, Grid, Typography, Box } from "@material-ui/core";

import { TwitterTimelineEmbed } from "react-twitter-embed";

import Layout from "../layout";
import Seo from "../seo";

import ContactForm from "../help/contact";

import { Mdx } from "@runly/gatsby-theme";
import { useTheme } from "@material-ui/core/styles";

const HelpPage = ({
	location,
	data: {
		site: {
			siteMetadata: { twitter }
		},
		file: {
			childMdx: {
				body: content,
				frontmatter: { title, description }
			}
		}
	}
}) => {
	const theme = useTheme();
	return (
		<Layout location={location} maxWidth="md">
			<Seo title={title} description={description} />

			<Container maxWidth="md">
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Typography variant="h3" component="h1">
							{title}
						</Typography>
					</Grid>
					<Grid item xs={12} md={6}>
						<Mdx>{content}</Mdx>

						<Box mt={6} mx="-10px">
							<TwitterTimelineEmbed
								key={theme.palette.type}
								sourceType="profile"
								screenName={twitter}
								theme={theme.palette.type}
								noHeader
								noFooter
								noBorders
								noScrollbar
								transparent
								autoHeight
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<ContactForm />
					</Grid>
				</Grid>
			</Container>
		</Layout>
	);
};

export default HelpPage;

export const helpQuery = graphql`
	query HelpQuery {
		site {
			siteMetadata {
				twitter
			}
		}
		file(name: { eq: "help" }, sourceInstanceName: { eq: "snippet" }) {
			childMdx {
				body
				frontmatter {
					title
					description
				}
			}
		}
	}
`;
